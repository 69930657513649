import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
declare var fbq: Function;

@Injectable({
  providedIn: 'root'
})
export class FacebookPixelTrackService {

  constructor() {}

  // Track ViewContent event
  public trackViewContent(contentIds: string[], contentType: string,content_name:string, currency: string, value :string, content_category:string) {
    const eventData = {
      content_ids: contentIds,
      content_type: contentType,
      content_name:content_name,
      currency:currency,
      value:value,
      content_category:content_category

    };
    fbq('track', 'ViewContent', eventData);
  }

  // Track AddToCart event
  public trackAddToCart(contentIds: string[], contentType: string, contents: any[], currency: string, value: number) {
    const eventData = {
      content_ids: [contentIds],
      content_type: contentType,
      contents: contents,
      currency: currency,
      value: value
    };
    fbq('track', 'AddToCart', eventData);
  }

  // Track Purchase event
  public trackPurchase(contentIds: string[], contentType: string, contents: any[], currency: string, value: number) {
    const eventData = {
      content_ids: [contentIds],
      content_type: contentType,
      contents: contents,
      currency: currency,
      value: value
    };
    console.log('Tracking Purchase Event:', eventData);
    fbq('track', 'Purchase', eventData);
  }
}
