import { Injectable } from '@angular/core';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root',
})
export class BckButtnService {
  constructor(public router: Router) {}
  public goToBack(vl: any) {
    this.router.navigate([`/${vl}`]);
  }
}
