import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { IonicModule } from '@ionic/angular';
import { BulkCsvPageRoutingModule } from './bulk-csv-routing.module';
import { BulkCsvPage } from './bulk-csv.page';
import { CategoryPickerModule } from '../category-picker/category-picker.module';
@NgModule({
  declarations: [
    BulkCsvPage
  ],
  imports: [
    CommonModule,
    FormsModule,
    IonicModule,
    ReactiveFormsModule,
    CategoryPickerModule,
    BulkCsvPageRoutingModule,
  ],
})
export class BulkCsvPageModule {}
