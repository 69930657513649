import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CharacterHidePipe } from './character-hide.pipe';
import { LineBreakPipe } from './line-break.pipe';

@NgModule({
  declarations: [
    CharacterHidePipe,
    LineBreakPipe
  ],
  imports: [
    CommonModule
  ],
  exports:[
    CharacterHidePipe,
    LineBreakPipe
  ]
})
export class PipesModule { }
