import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'characterHide'
})
export class CharacterHidePipe implements PipeTransform {

  transform(value: string): string {
    return value.replace(/\/n/g, '\n,');
  }

}
